<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px; padding-top:5px;  padding-left: 5px;">
            <el-form :inline="true" :model="filters" label-width="auto" size="small" label-position="left">
                <el-form-item label="推广账号" prop="advAccountName" >
                    <el-select size="small" v-model="filters.advAccountName" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in bindAdvAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户账号" prop="guestAccountId" v-if="!gconfig.isGuest()">
                    <el-select size="small" v-model.number="filters.guestAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运营账号" prop="staffAccountId" v-if="gconfig.isAdmin()" >
                    <el-select size="small" v-model.number="filters.staffAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in staffAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="绑定域名" prop="bindDomain" >
                    <el-select size="small" v-model="filters.bindDomain" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in bindDomainOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="成交情况" prop="deal" v-if="!gconfig.isGuest()" >
                    <el-select size="small" v-model="filters.deal" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in dealOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="订单状态">
                    <el-select size="small" v-model="filters.orderFlag" value="" style="width:100px" filterable clearable>
                        <el-option v-for="item in orderOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电话/域名/名字" prop="domainOrPhone" >
                    <el-input size="small" v-model="filters.domainOrPhone" clearable style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="时间" >
                    <el-date-picker size="small" v-model="filters.timeRange" type="daterange" value-format="timestamp"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" size="small" v-on:click="handleOrderList()" style="width:50px">查询</el-button>
                </el-form-item>
                <el-form-item v-if="!gconfig.isStaff()" >
                    <export-file @download="handleExport" style="width:150px" />
                </el-form-item>
                <el-form-item v-if="!gconfig.isStaff()">
                    <el-button size="mini" type="primary" :disabled="selections.length==0" v-on:click="showBatchModify('hide')">批量隐藏</el-button>
                </el-form-item>

                <!-- <el-form-item label="" style="">
                    <el-checkbox-group v-model="checkedColumns" @change="setCheckedColumns">
                      <el-checkbox v-for="column in this.columnGroups" :label="column" :key="column">{{ column }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;" @selection-change="selectionChange">
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="title" label="下单名字" width="100" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="tel" label="下单电话" width="100"></el-table-column>
                <el-table-column prop="xddz" label="下单地址" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="szdq" label="下单商品" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="content" label="下单备注" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="time" label="下单时间" width="150" :formatter="formatTime"></el-table-column>
                <el-table-column prop="visit_time" label="下单用时" width="100" v-if="gconfig.isAdmin()"></el-table-column>
                <el-table-column prop="adv_account" label="推广账号" width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.adv_account== ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ scope.row.adv_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="客户账号" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_guest == ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;"> {{ scope.row.account_guest }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="运营账号" width="100" v-if="!gconfig.isGuest()">
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_staff == ''">未绑定</span>
                        <span v-else style="color:orange; font-weight: bold;"> {{ scope.row.account_staff }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="wwwdb" label="下单域名" width="150"></el-table-column>
                <el-table-column prop="ip" label="来源ip" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="url" label="来源页面" width="150" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <a target="_blank" :href="scope.row.url">{{ scope.row.url }}</a>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="feedback2" label="百度回传" width="100"></el-table-column>
                <el-table-column prop="feedback1" label="360回传" width="100"></el-table-column> -->
                <el-table-column prop="id" label="订单号" width="100"></el-table-column>
                <el-table-column prop="quantity" label="购买数量" width="100"></el-table-column>
                <el-table-column prop="payment" label="支付方式" width="100"></el-table-column>
                <el-table-column prop="zcode" label="邮编" width="100"></el-table-column>
                <el-table-column prop="invalid" label="无效订单" width="100"></el-table-column>
                <el-table-column label="操作" min-width="270" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="danger" size="small" @click="handleDelete(scope.row)" v-if="gconfig.isAdmin()"
                            style="width: 50px; margin-left: 3px;">删除</el-button>

                        <!-- <el-button size="small" @click="handleFeedback(scope.row, 10)" :disabled="scope.row.feedback2>0"
                            v-if="checkShowFeedbackBaidu(scope.row)" style="width: 60px; margin-left: 3px;">bd回传</el-button> -->

                        <!-- <el-button size="small" @click="handleFeedback(scope.row, 0)" :disabled="scope.row.feedback1>0"
                            v-if="checkShowFeedback360(scope.row)" style="width: 60px; margin-left: 3px;">360回传</el-button> -->

                        <el-button size="small" @click="handleDealOrder(scope.row)" v-if="!gconfig.isStaff()"
                            :style="'width: 60px; margin-left:3px;font-weight:bold;' + (scope.row.deal==1 ? 'color:green;' : 'color:gray;')">成交</el-button>

                        <el-button size="small" @click="handleHideOrder(scope.row)" v-if="gconfig.isAdmin()"
                            :style="'width: 50px; margin-left:3px;font-weight:bold;' + (scope.row.hide == 0 ? 'color:green' : 'color:gray')">隐藏</el-button>

                        <el-button size="small" @click="handleInvlidOrder(scope.row)" v-if="gconfig.isGuest()"
                            :style="'width: 50px; margin-left:3px;font-weight:bold;' + (scope.row.invalid == 0 ? 'color:green' : 'color:gray')">无效</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page" :total="total"
                    :page-size="pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col>

            
            <!-- batchmodify -->
            <el-dialog :title="batchModifyTitle" :visible.sync="batchModifyVisible" :close-on-click-modal="false" width="30%">
                <el-form :model="batchForm" label-width="100px" ref="batchform" label-position="left">
                    <el-col :span="24" v-if="batchForm.modType=='hide'">
                        <el-select key="1" size="small" v-model="batchForm.modValue" value="" style="width:70%">
                            <el-option :key=0 :value=0 label="显示"></el-option>
                            <el-option :key=1 :value=1 label="隐藏"></el-option>
                        </el-select>
                    </el-col>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click.native="formCancel('batchform')">取消</el-button>
                    <el-button type="primary" @click.native="handleBatchModify" :loading="formLoading">提交</el-button>
                </div>
            </el-dialog>
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import model from "../../common/js/model";
import gconfig from "../../api/gconfig";
import { export_json_to_excel as Export2Excel } from '@/common/js/export2Excel'
import { filterRuleType, formatJSON, mapGenerator } from '@/common/js/formatJSON'
import { postRequest, formatTimestamp, getDotTime, getUser, dateFormat2 } from "../../api/api";

export default {
    data() {
        return {
            filters: {
                // deal: 0,
                // bindDomain: "",
                // accountName: "",
                advAccountName: "",
                staffAccountId: "",
                guestAccountId: "",
                domainOrPhone: "",
                orderFlag: "",
                timeRange: [getDotTime() * 1000, getDotTime() * 1000],
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            accountOptions: [],
            bindDomainOptions: [],
            gconfig: gconfig,
            dealOptions: [
                {label:"不限", value:0},
                {label:"已成交", value:1},
            ],
            orderOptions: [
                {label:"成交", value:1},
                {label:"无效", value:2},
            ],

            bindAdvAccountOptions: [],
            guestAccountOptions: [],
            staffAccountOptions: [],

            batchForm: {
                modType: "",
                modValue: "",
            },
            batchModifyTitle: "",
            batchModifyVisible: false,

            selections: [],
            columnGroups: ['其他'],
            checkedColumns: [],
            formLoading: false
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        checkShowFeedbackBaidu(row) {
            return !gconfig.isStaff() && row.url.indexOf("bd_vid") >= 0;
        },

        checkShowFeedback360(row) {
            return gconfig.isAdmin() && row.url.indexOf("sourceid") >= 0;
        },

        handleOrderList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                advAccountName: this.filters.advAccountName,
                staffAccountId: this.filters.staffAccountId ? this.filters.staffAccountId : 0,
                guestAccountId: this.filters.guestAccountId ? this.filters.guestAccountId : 0,
                domainOrPhone: this.filters.domainOrPhone,
                orderFlag: this.filters.orderFlag==="" ? 0 : this.filters.orderFlag,
                timeRange: this.filters.timeRange ? this.filters.timeRange : [0, 0],
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "GetOrderList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("GetOrderList", this.dataList);

            });
        },

        handleAccountIdNameList() {
            let params = { needPrivilege: true };
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetAccountIdNameList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.accountOptions = res.data.datas;
                console.log("handleAccountIdNameList", this.accountOptions);

            });
        },

        handleBindDomainOptions() {
            let params = {}
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("DomainMgrCtrl", "GetBindDomainOptions", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.bindDomainOptions = res.data.datas;
                console.log("handleBindDomainOptions", this.bindDomainOptions);

            });
        },

        handleDelete(row) {
            this.$confirm("确认删除吗?", "提示", { type: "warning" }).then(() => {
                let params = {
                    id: row.id,
                }
                let queryModel = model.GetQueryModel("OrderMgrCtrl", "DeleteOrder", params);
                postRequest(queryModel).then(res => {
                    if (res.data.msg !== "") {
                        this.$message.warning(res.data.msg);
                        return;
                    }
                    this.handleOrderList();

                });
            });
        },

        handleFeedback(row, feedbackType) {
            let params = { id: row.id, feedbackType: feedbackType };
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "FeedbackManual", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.$message.success("回传成功");
                this.handleOrderList();
            });
        },
      
        handleDealOrder(row) {
            let params = { id: row.id, deal: row.deal == 1 ? 0 : 1 };
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "HandleDealOrder", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.$message.success("设置成功");
                this.handleOrderList();
            });
        },

        handleHideOrder(row) {
            let params = { id: row.id, hide: row.hide == 1 ? 0 : 1 };
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "HandleHideOrder", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.$message.success("设置成功");
                this.handleOrderList();
            });
        },

        handleInvlidOrder(row) {
            let params = { id: row.id, invalid: row.invalid == 1 ? 0 : 1 };
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "HandleInvalidOrder", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.$message.success("设置成功");
                this.handleOrderList();
            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleOrderList(page);
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleOrderList();
        },

        handleExport(exportData) {
            let headers = [
                '订单号',
                '绑定账号',
                '下单域名',
                '下单名字',
                '下单电话',
                '下单地址',
                '下单商品',
                '下单备注',
                '来源页面',
                '来源ip',
                '下单时间',
                '无效订单'
            ]
            let filterRule = [
                'id',
                'account',
                'wwwdb',
                'title',
                'tel',
                'xddz',
                'szdq',
                'content',
                'url',
                'ip',
                {
                    filterVal: 'time',
                    convert: filterRuleType.TIME,
                    fn: val => formatTimestamp(val),
                },
                'invalid',
            ]
            let datas = formatJSON(filterRule, this.dataList)
            Export2Excel({
                header: headers,
                data: datas,
                filename: exportData.fileName || 'orders_export_' + dateFormat2(null)
            })
        },

        
        handleBindAdvAccountList() {
            let params = {}
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetBindAdvAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.bindAdvAccountOptions.push({label:val.uc_name, value:val.uc_name, platform:val.platform});
                }
                console.log("handleBindAdvAccountList", this.bindAdvAccountOptions);

            });
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },

        handleStaffAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetStaffAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.staffAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleStaffAccountList", this.staffAccountOptions);

            });
        },

        setCheckedColumns() {
            window.localStorage.setItem('ordermgr_checked_columns', JSON.stringify(this.checkedColumns));
        },
        getCheckedColumns() {
            this.checkedColumns = JSON.parse(window.localStorage.getItem("ordermgr_checked_columns")) || [];
        },

        showBatchModify(type) {
            this.batchForm.modType = type;
            this.batchForm.modValue = "";
            if (type == "hide") {
                this.batchForm.modValue = 1;
                this.batchModifyTitle = "批量隐藏";
            } 
            this.batchModifyVisible = true;
        },

        handleBatchModify() {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
                let params = Object.assign({}, this.batchForm);
                params.selections = [];
                for (let v of this.selections) {
                    params.selections.push(v.id);
                }
                this.formLoading = true;
                var queryModel = model.GetQueryModel("OrderMgrCtrl", "HandleBatchModify", params);
                postRequest(queryModel).then(res => {
                    this.formLoading = false;
                    this.batchModifyVisible = false;
                    if (!util.isEmpty(res.data.msg)) {
                        this.$message.warning(res.data.msg);
                    } 
                    let notice = "成功:"+res.data.datas.succ_num+" 失败:"+res.data.datas.fail_num
                    this.$message.success(notice);
                    this.handleOrderList();
                });
            });
        },

        selectionChange(selections) {
            this.selections = selections;
        },

        formCancel(ref) {
            if (ref == "batchform") {
                this.batchModifyVisible = false;
            }
            this.$refs[ref].resetFields();
        },

    },
    mounted() {
        // this.handleAccountIdNameList();
        // this.handleBindDomainOptions();
        this.getCheckedColumns()

        this.handleGuestAccountList();
        this.handleStaffAccountList();
        this.handleBindAdvAccountList();
        this.handleOrderList();
    }
}
</script>
<style scoped></style>